.alert {
  padding: 1rem;
  background-color: #ff4040;
  color: white;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.alertMesagge {
  margin-bottom: 0rem;
}